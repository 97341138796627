import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { handleLogout } from "../../../../redux/features/userSlice";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deactivateMerchant } from "../../../../redux/features/adminSlice";
import i18next from "i18next";
import styles from "./ShopDeactivate.module.scss";

export default function ShopDeactivate(props) {
  const [okdisabled, setOkDisabled] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.cpc.user.login.token);

  const merchantPhone = props.handlemerchantphone;

  console.log(merchantPhone);

  return (
    <div className={`${styles.deactivateModalContent}`}>
      <Modal {...props} size="md" centered>
        <Modal.Body className={`${styles.giftPointBody}`}>
          <h4 className="mb-3">{t("confirmDeactivate")}</h4>
          <p>{t("deactivateModalParagraph")}</p>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              size="sm"
              variant="danger"
              className={`${styles.okBtn} m-2`}
              onClick={() => {
                setOkDisabled(true);
                const loadReq = toast.loading(t("loading"), {
                  transition: Zoom,
                  position:
                    i18next.language == "ar" ? "bottom-right" : "bottom-left",
                });
                setTimeout(() => {
                  setOkDisabled(false);
                }, 3000);
                dispatch(
                  deactivateMerchant({
                    token,
                    merchantPhone,
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                    successMessage(
                      res.payload.data.message,
                      res.payload.data.messageAr,
                      loadReq
                    );
                  } else if (res.payload.data.statusCode === 401) {
                    dispatch(handleLogout());
                    navigate("/cpc/login");
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data.errorAr,
                      loadReq
                    );
                  } else {
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data.errorAr,
                      loadReq
                    );
                  }
                });
              }}
              disabled={okdisabled}
            >
              {t("yes")}
            </Button>
            <Button
              size="sm"
              variant="danger"
              className={`${styles.noBtn} m-2`}
              onClick={() => {
                props.onHide();
              }}
            >
              {t("no")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
