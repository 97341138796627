import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import styles from "./StationOrdersReport.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { handleLogout } from "../../../../../redux/features/userSlice";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FaUserTag } from "react-icons/fa";
import { Formik } from "formik";
import { BsFuelPumpFill } from "react-icons/bs";
import "./StationOrders.css";
import CustomerInfo from "./customerInfo/CustomerInfo";
import StationInfo from "./stationInfo/StationInfo";

export default function StationOrdersReport() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const girdRef = useRef();

  const token = useSelector((state) => state.cpc.user.login.token);

  const { t } = useTranslation();

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Customer Number",
      field: "customer.phone",
    },
    {
      headerName: "Order ID",
      field: "order_id",
      minWidth: 500,
    },
    {
      headerName: "Amount",
      field: "amount",
    },
    {
      headerName: "Points",
      field: "points_used",
    },
    {
      headerName: "Station Name",
      field: "station.station_name",
    },
    {
      headerName: "Governorate",
      field: "station.governorate.governorate_name_en",
    },
    {
      headerName: "District",
      field: "station.city.city_name_en",
    },
    {
      headerName: "Operator Number",
      field: "station.operator.phone_number",
    },
    {
      headerName: "User",
      field: "station",
      cellRenderer: (result) => {
        console.log(result.data);
        let customer = result.data.customer;
        let station = result.data.station;

        console.log(station);
        return (
          <div>
            <Button
              variant="primary"
              className={`${styles.customerBtn} mx-1`}
              size="sm"
              onClick={() => {
                setCustomerShow(true);
                setCustomerInfo(customer);
              }}
            >
              <FaUserTag />
            </Button>
          </div>
        );
      },
    },
    {
      headerName: "Date & Time",
      field: "createdAt",
    },
  ]);

  const [rowData, setRowData] = useState([]);
  const [message, setMessage] = useState("");
  const [filter, setFilter] = useState({
    sort: "asc",
    sortBy: "createdAt",
    fromDate: "",
    toDate: "",
    customerPhone: "",
    operatorPhone: "",
    stationId: "",
    operatorId: "",
    govId: "",
    cityId: "",
    limit: 10,
    page: "",
    offset: "",
  });

  const [customerInfo, setCustomerInfo] = useState([]);
  const [customerShow, setCustomerShow] = useState(false);

  const [stationInfo, setStationInfo] = useState([]);
  const [stationShow, setStationShow] = useState(false);

  console.log(stationInfo);

  useEffect(() => {
    axios
      .get(
        "https://evouchers.shareecoin.com/CPC/fuelStation/orders/getReport",
        {
          params: {
            sort: "asc",
            sortBy: "createdAt",
            fromDate: filter.fromDate,
            toDate: filter.toDate,
            customerPhone: filter.customerPhone,
            operatorPhone: filter.operatorPhone,
            stationId: "",
            operatorId: "",
            govId: filter.govId,
            cityId: filter.cityId,
            limit: 10,
            page: "",
            offset: "",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setRowData(res.data.orders);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [filter]);

  const defaultColDef = {
    flex: 1,
    sortable: false,
    filter: false,
    floatingFilter: false,
    minWidth: 200,
  };

  const [startDate, setStartDate] = useState(new Date());

  const [cities, setCities] = useState([]);
  const [governs, setGoverns] = useState([]);
  const [governID, setGovernID] = useState(null);
  const [activeCity, setAcitveCity] = useState(false);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setGoverns(res.data["Governates List"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: governID,
        Function: "ListCites",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCities(res.data["List of Cities"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [governID]);

  return (
    <div className={`${styles.StationOrdersReportContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div
              className="ag-theme-alpine ag-theme-quartz"
              style={{ width: "100%", height: `70vh` }}
            >
              <AgGridReact
                ref={girdRef}
                // onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={rowData}
                pagination={true}
                paginationAutoPageSize={true}
              />
            </div>
          </Col>
          {/* <div className="text-center mt-3">
            {pages.map((page, index) => {
              return (
                <Button
                  variant="primary"
                  size="sm"
                  key={index}
                  className={`${styles.pageBtn} mx-1 `}
                >
                  {page}
                </Button>
              );
            })}
          </div> */}
          <Col xs={12} className="my-3 text-center">
            <Button
              variant="primary"
              className={`${styles.downloadBtn}`}
              onClick={() => {}}
            >
              {t("downloadReport")}
            </Button>
          </Col>
        </Row>
      </Container>
      <CustomerInfo
        show={customerShow}
        onHide={() => {
          setCustomerShow(false);
        }}
        customerinfo={customerInfo}
      />
      <StationInfo
        show={stationShow}
        onHide={() => {
          setStationShow(false);
        }}
        stationinfo={stationInfo}
      />
    </div>
  );
}
