import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BsQrCodeScan } from "react-icons/bs";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import Qrscan from "./qrscan/Qrscan";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import i18next from "i18next";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { handleLogout } from "../../../../redux/features/userSlice";
import {
  getPointsValue,
  redeemFuelVoucher,
} from "../../../../redux/features/customerSlice";
import styles from "./CustomerFuel.module.scss";
import {
  confirmRequestCode,
  saveCarPlate,
  sendFuelRequest,
  verifyFuelVoucher,
} from "../../../../redux/features/customerSlice";
import { FaCheckSquare } from "react-icons/fa";
import { FaRegCheckSquare } from "react-icons/fa";

export default function CustomerFuel() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fuelValue, setFuelValue] = useState(1);
  const [showQrscan, setShowQrscan] = useState(false);
  const [stations, setStations] = useState([]);
  const [governs, setGoverns] = useState([]);
  const [cities, setCities] = useState([]);
  const [governID, setGovernID] = useState("");
  const [city, setCity] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [remember, setRemember] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [finalResult, setFinalResult] = useState("");
  const [final, setFinal] = useState(null);
  const [fuelVoucherCode, setFuelVoucherCode] = useState("");

  const [activeStationInput, setActiveStationInput] = useState(false);
  const [activeCity, setActiveCity] = useState(false);
  const [redeemDisable, setRedeemDisable] = useState(true);
  const [fuelCardValue, setFuelCardValue] = useState("00");
  const [sendDisabled, setSendDisabled] = useState(true);

  const [fuelValues, setFuelValues] = useState([]);

  const token = useSelector((state) => state.cpc.user.login.token);

  const confirmcodeValidation = yup.object().shape({
    confirmCode: yup.string().required("رمز تاكيد الطلب مطلوب"),
  });

  const govIDD = useSelector((state) => state.cpc.user.login.paymentInfo.govID);
  const oberatorID = useSelector(
    (state) => state.cpc.user.login.paymentInfo.operatorID
  );

  const [paymentGov, setPaymentGov] = useState({
    gov: "",
    govAr: "",
  });
  const [paymentCity, setPaymentCity] = useState({
    city: "",
    cityAr: "",
  });

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuel-vouchers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setFuelValues(res.data["fuel_voucher_denomination"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/customer/login");
        }
      });
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setGoverns(res.data["Governates List"]);
          if (finalResult !== "") {
            const filterGov = res.data["Governates List"].filter(
              (targetGov) =>
                targetGov.GovernateID == finalResult.stationInfo["gov_id"]
            );
            handleCities(finalResult.stationInfo["gov_id"]);
            filterGov.map((filgov) => {
              setPaymentGov({
                gov: filgov["governorate_name_en"],
                govAr: filgov["governorate_name_ar"],
              });
            });
          }
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/customer/login");
        }
      });
  }, [finalResult]);

  const cityIDD = useSelector(
    (state) => state.cpc.user.login.paymentInfo.cityID
  );
  const stationName = useSelector(
    (state) => state.cpc.user.login.paymentInfo.stationName
  );

  const handleCities = (govID) => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: govID,
        Function: "ListCites",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCities(res.data["List of Cities"]);
          const filterCity = res.data["List of Cities"].filter(
            (targetCity) =>
              targetCity.cityID == finalResult.stationInfo["city_id"]
          );
          filterCity.map((filcit) => {
            setPaymentCity({
              city: filcit["city_name_en"],
              cityAr: filcit["city_name_Ar"],
            });
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/customer/login");
        }
      });
  };

  const handleStations = (govID, cityID, token) => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/stations", {
        params: {
          governorateID: govID,
          cityID: cityID,
          sort: "asc",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          setStations(res.data.data);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/customer/login");
        } else {
          setStations([]);
        }
      });
  };

  const [finalValue, setFinalValue] = useState("000");

  const [scanQrmood, setScanQrmood] = useState(false);

  const [savedCar, setSavedCar] = useState("");
  const [pendTrans, setPendTrans] = useState({
    status: false,
    id: null,
  });
  const [notActiveContent, setNotActiveContent] = useState(false);

  const [transID, setTransID] = useState("");

  useEffect(() => {
    axios
      .get(
        "https://evouchers.shareecoin.com/CPC/fuelStation/car/platteNumber",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setSavedCar(res.data.data["car_data"]);
          if (res.data.data["car_data"]["plate_number"] !== "") {
            setRemember(true);
          }
          setPendTrans({
            status: res.data.data["pendingTransaction"],
            id: res.data.data["pending_payment_transaction_id"],
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/customer/login");
        }
      });
  }, []);

  const savedValues = {
    carplateLetter: savedCar["plate_number_letters"],
    carplateNum: savedCar["plate_number_numbers"],
    saveCarPlate: true,
    fuelValue: "",
    stationName: "",
    voucherCode: "",
    vouchertype: "",
  };
  const initalValues = {
    carplateLetter: "",
    carplateNum: "",
    saveCarPlate: false,
    fuelValue: "",
    stationName: "",
    voucherCode: "",
    vouchertype: "",
  };

  const [alertMessage, setAlertMessage] = useState();

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  console.log(finalResult);

  const handleFinalResult = (target) => {
    console.log(target);
    setFinalResult(JSON.parse(target));
  };

  console.log(fuelVoucherCode);

  return (
    <div className={`${styles.CustomerFuelContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <h4 className={`${styles.redeemCashoutTitle}`}>
              {t("customerFuelNote")}
            </h4>
            {pendTrans.status === true && (
              <h4 className={`${styles.savedCarNote} my-3`}>
                لديك طلب دفع لرقم لوحة {savedCar["plate_number"]}
              </h4>
            )}
          </Col>
          <Col xs={12}>
            <Formik
              initialValues={savedValues || initalValues}
              onSubmit={(values, actions) => {
                console.log(values);
                const loadReq = toast.loading(t("loading"), {
                  transition: Zoom,
                  position:
                    i18n.language === "ar" ? "bottom-right" : "bottom-left",
                });

                dispatch(
                  sendFuelRequest({
                    token,
                    fuelValue: values.fuelValue,
                    carplateLetter:
                      values.carplateLetter !== ""
                        ? values.carplateLetter
                        : savedCar["plate_number_letters"],
                    carplateNumber:
                      values.carplateNum !== ""
                        ? values.carplateNum
                        : savedCar["plate_number_numbers"],
                    stationName:
                      values.stationName === "" && finalResult !== ""
                        ? finalResult.stationID
                        : values.stationName,
                    oberatorID: finalResult.operatorInfo["operator_id"],
                    stationID: finalResult.stationInfo["station_id"],
                    voucherCode: values.voucherCode,
                    vouchertype: values.vouchertype,
                  })
                ).then((res) => {
                  console.log(res);
                  if (res.payload.data.statusCode === 200) {
                    setNotActiveContent(true);
                    if (fuelValue === 2) {
                      setFuelVoucherCode(res.meta.arg.voucherCode);
                    }
                    if (res.payload.data.success === true) {
                      setAccepted(true);
                      setTransID(res.payload.data.data["transaction_id"]);
                    }
                    successMessage(
                      res.payload.data.message,
                      res.payload.data.messageAr,
                      loadReq
                    );
                  } else if (res.payload.data.statusCode === 401) {
                    dispatch(handleLogout());
                    navigate("/cpc/customer/login");
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data.errorAr,
                      loadReq
                    );
                  } else {
                    setTimeout(() => {
                      actions.setSubmitting(false);
                    }, 3000);
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data.errorAr,
                      loadReq
                    );
                  }
                });
              }}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <Form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => checkKeyDown(e)}
                  >
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={12} className="mb-2">
                        <Form.Label className="d-block">
                          {t("vehiclePlateNumber")}
                        </Form.Label>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={3} className="mb-2">
                        <Form.Control
                          type="text"
                          className={`${styles.cashoutInput} ${styles.carplateLetterStyle}`}
                          size="sm"
                          id="carplateLetter"
                          name="carplateLetter"
                          onChange={(e) => {
                            if (e.currentTarget.value === "") {
                              setNotActiveContent(true);
                            } else {
                              setNotActiveContent(false);
                            }
                            setRemember(false);
                            let string = e.target.value;
                            let array = string.split("");
                            let newStr = array.join(" ");
                            setFieldValue("carplateLetter", newStr);
                          }}
                          defaultValue={values.carplateLetter}
                        />
                        {errors.carplateLetter && touched.carplateLetter && (
                          <Form.Text className="text-error">
                            {errors.carplateLetter}
                          </Form.Text>
                        )}
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={3} className="mb-2">
                        <Form.Control
                          type="text"
                          className={`${styles.cashoutInput}`}
                          size="sm"
                          id="carplateNum"
                          name="carplateNum"
                          onChange={(e) => {
                            setRemember(false);
                            if (e.currentTarget.value === "") {
                              setNotActiveContent(true);
                            } else {
                              setNotActiveContent(false);
                            }
                            setFieldValue("carplateNum", e.target.value);
                          }}
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          defaultValue={values.carplateNum}
                        />
                        {errors.carplateNum && touched.carplateNum && (
                          <Form.Text className="text-error">
                            {errors.carplateNum}
                          </Form.Text>
                        )}
                      </Col>
                      <Col xs={12} md={6} className="mb-2">
                        <input
                          hidden
                          type="checkbox"
                          id="saveCarPlate"
                          name="saveCarPlate"
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              if (
                                values.carplateLetter !== "" &&
                                values.carplateNum !== ""
                              ) {
                                setRemember(true);
                                dispatch(
                                  saveCarPlate({
                                    token,
                                    carplateLetter: values.carplateLetter,
                                    carplateNumber: values.carplateNum,
                                  })
                                ).then((res) => {
                                  // console.log(res);
                                });
                              }
                            }
                          }}
                          checked={remember}
                        />

                        <label htmlFor="saveCarPlate">
                          {remember === true ? (
                            <FaCheckSquare className="mx-1" />
                          ) : (
                            <MdCheckBoxOutlineBlank className="mx-1" />
                          )}
                          {t("saveNumber")}
                        </label>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      {notActiveContent === true && (
                        <div className={`${styles.notActiveContent}`}></div>
                      )}{" "}
                      <Col xs={12} md={12} className="mb-2">
                        <Form.Label className="d-block">
                          {t("stationInfo")}
                        </Form.Label>
                      </Col>
                      <Col xs={12}>
                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <div
                            className={`${styles.scanStationContent} mb-3`}
                            onClick={() => {
                              setShowQrscan(true);
                              setFieldValue("stationName", "");
                            }}
                          >
                            <BsQrCodeScan className="mx-2" />
                            {t("scantionStationQrCode")}
                          </div>
                          {finalResult !== "" && (
                            <div className={`${styles.stationInfoContent}`}>
                              <p className="text-center m-1">
                                {`${
                                  i18next.language == "ar"
                                    ? paymentGov.govAr
                                    : paymentGov.gov
                                }, ${
                                  i18next.language == "ar"
                                    ? paymentCity.cityAr
                                    : paymentCity.city
                                } - ${finalResult.stationInfo["station_name"]}`}
                              </p>
                            </div>
                          )}
                        </div>
                      </Col>
                      {/* <Col xs={12} className="mt-3 mb-3">
                          <Form.Text>
                            او اسال العامل للتاكد من اختيار المحطة الصحيحة
                          </Form.Text>
                        </Col>
                        <Col xs={12}>
                          <Row>
                            <Col xs={12} sm={6} md={4} className="mb-2">
                              <Form.Select
                                className={`${styles.cashoutSelect}`}
                                name="stationGov"
                                id="stationGov"
                                onChange={(e) => {
                                  setFinalResult("");
                                  setFieldValue(
                                    "stationGov",
                                    e.currentTarget.value
                                  );
                                  handleCities(e.currentTarget.value);
                                  setActiveCity(true);
                                }}
                              >
                                <option value="">المحافظة</option>
                                {governs.map((gov, index) => {
                                  return (
                                    <option
                                      key={gov.GovernateID}
                                      value={gov.GovernateID}
                                    >
                                      {i18next.language == "ar"
                                        ? gov["governorate_name_ar"]
                                        : gov["governorate_name_en"]}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Col>
                            <Col xs={12} sm={6} md={4} className="mb-2">
                              <Form.Select
                                className={`${styles.cashoutSelect}`}
                                name="stationCity"
                                id="stationCity"
                                onChange={(e) => {
                                  setFieldValue(
                                    "stationCity",
                                    e.currentTarget.value
                                  );
                                  handleStations(
                                    values.stationGov,
                                    e.currentTarget.value,
                                    token
                                  );
                                  setActiveStationInput(true);
                                }}
                              >
                                <option value="">المنطقة</option>
                                {cities.map((city, index) => {
                                  return (
                                    <option
                                      key={city.cityID}
                                      value={city.cityID}
                                    >
                                      {i18next.language == "ar"
                                        ? city["city_name_Ar"]
                                        : city["city_name_en"]}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Col>
                            <Col xs={12} sm={12} md={4} className="mb-2">
                              <Form.Select
                                className={`${styles.cashoutSelect}`}
                                name="stationName"
                                id="stationName"
                                disabled={!activeStationInput}
                                onChange={(e) => {
                                  setFieldValue(
                                    "stationName",
                                    e.currentTarget.value
                                  );
                                }}
                              >
                                <option value="">اسم المحطة</option>
                                {stations.map((station, index) => {
                                  return (
                                    <option
                                      key={station["station_id"]}
                                      value={station["station_id"]}
                                    >
                                      {station["station_name"]}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Col>
                            {finalResult !== "" && (
                              <Col xs={12} className="my-3">
                                <Form.Text className="d-block text-center">
                                  المحطة هي:
                                  <span
                                    className={`${styles.stationNameStyle} mx-3`}
                                  >
                                    {finalResult.stationName}
                                  </span>
                                </Form.Text>
                              </Col>
                            )}
                          </Row>
                        </Col> */}
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      {notActiveContent === true || finalResult == "" ? (
                        <div className={`${styles.notActiveContent}`}></div>
                      ) : null}

                      <Col xs={12} md={12} className="mb-2">
                        <Form.Label className="d-block">
                          {t("fuelValue")}
                        </Form.Label>
                        <div
                          className={`${styles.fuelChoices} mb-3 d-flex justify-content-center align-items-center`}
                        >
                          <Button
                            variant="danger"
                            size="sm"
                            className={`${
                              fuelValue === 1
                                ? styles.walletChoiceBtn
                                : styles.walletnotActiveBtn
                            } mx-2`}
                            onClick={() => {
                              if (fuelValue == 2) {
                                setSendDisabled(true);
                              }
                              setFuelCardValue("00");
                              setFuelValue(1);
                            }}
                            disabled={notActiveContent === true ? true : false}
                          >
                            {t("redeemFuelPoints")}
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            className={`${
                              fuelValue === 2
                                ? styles.walletChoiceBtn
                                : styles.walletnotActiveBtn
                            } mx-2`}
                            onClick={() => {
                              if (fuelValue == 1) {
                                setSendDisabled(true);
                              }
                              setFinalValue("000");
                              setFuelValue(2);
                            }}
                            disabled={notActiveContent === true ? true : false}
                          >
                            {t("redeemFuelGiftCard")}
                          </Button>
                        </div>
                      </Col>
                      {fuelValue == 1 ? (
                        <Fragment>
                          <Col
                            xs={12}
                            md={{ span: 10, offset: 1 }}
                            className="position-relative"
                          >
                            {pendTrans.status === true && (
                              <div
                                className={`${styles.notActiveContent}`}
                              ></div>
                            )}
                            <div className={`${styles.valuesContent}`}>
                              {fuelValues.map((fValue, index) => {
                                return (
                                  <div className="m-2" key={index}>
                                    <input
                                      hidden
                                      type="radio"
                                      id={`fuelValues-${index}`}
                                      name={`fuelValue`}
                                      className={`${styles.fuelValue}`}
                                      onChange={(e) => {
                                        if (e.target.checked === true) {
                                          dispatch(
                                            getPointsValue({
                                              token,
                                              amount: Number(fValue),
                                              cashType: "fuel",
                                            })
                                          ).then((res) => {
                                            setAlertMessage(null);
                                            if (
                                              res.payload.data.statusCode ===
                                              200
                                            ) {
                                              setSendDisabled(false);
                                              setFinalValue(
                                                res.payload.data.pointsNeeded
                                              );
                                              setFieldValue(
                                                "fuelValue",
                                                fValue
                                              );
                                            } else {
                                              setAlertMessage(
                                                <span
                                                  className={`${styles.alertMessageContent} px-3 py-2`}
                                                >
                                                  {i18n.language === "ar"
                                                    ? res.payload.data.errorAr
                                                    : res.payload.data.error}
                                                </span>
                                              );
                                              setFinalValue("00");
                                            }
                                          });
                                        }
                                      }}
                                    />
                                    <label
                                      htmlFor={`fuelValues-${index}`}
                                      className={`${styles.fuelValueBtn}`}
                                    >
                                      {fValue} {t("le")}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </Col>
                          <Col xs={12} className="mt-3">
                            <Form.Text>
                              <p className={`${styles.totalValuepara}`}>
                                {t("fuelValueNote")}
                                <span
                                  className={`${styles.finalValueStyle} m-3 px-3 py-2`}
                                >
                                  {finalValue}
                                </span>
                                {t("point")}
                              </p>
                            </Form.Text>
                          </Col>
                          <Col xs={12} className="mt-2">
                            {alertMessage !== null && alertMessage}
                          </Col>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Col xs={12} md={4}>
                            <Form.Label>ادخل كود كارت الوقود</Form.Label>
                          </Col>
                          <Col
                            xs={12}
                            sm={{ span: 8, offset: 2 }}
                            md={{ span: 6, offset: 3 }}
                          >
                            <InputGroup
                              size="sm"
                              className={`${styles.redeemBtnContent}`}
                            >
                              <InputGroup.Text
                                id="inputGroup-sizing-sm"
                                className={`${styles.redeemBtnText}`}
                              >
                                <Button
                                  size="sm"
                                  variant="danger"
                                  className={`${styles.redeemBtn}`}
                                  disabled={redeemDisable}
                                  onClick={() => {
                                    setRedeemDisable(true);
                                    const loadReq = toast.loading(
                                      t("loading"),
                                      {
                                        transition: Zoom,
                                        position:
                                          i18n.language === "ar"
                                            ? "bottom-right"
                                            : "bottom-left",
                                      }
                                    );
                                    setTimeout(() => {
                                      setRedeemDisable(false);
                                    }, 3000);
                                    dispatch(
                                      verifyFuelVoucher({
                                        voucherCode: values.voucherCode,
                                        token,
                                      })
                                    ).then((res) => {
                                      console.log(res);
                                      if (res.payload.data.statusCode === 200) {
                                        setFieldValue(
                                          "fuelValue",
                                          res.payload.data.fuelVoucher[
                                            "voucher_denomination"
                                          ]
                                        );
                                        setFieldValue(
                                          "vouchertype",
                                          res.payload.data.fuelVoucher[
                                            "vouchertype"
                                          ]
                                        );
                                        setSendDisabled(false);
                                        dispatch(
                                          getPointsValue({
                                            token,
                                            amount: Number(
                                              res.payload.data.fuelVoucher[
                                                "voucher_denomination"
                                              ]
                                            ),
                                            cashType: "fuel",
                                          })
                                        ).then((res) => {
                                          if (
                                            res.payload.data.statusCode === 200
                                          ) {
                                            setFuelCardValue(
                                              res.payload.data.pointsNeeded
                                            );
                                          }
                                        });
                                        successMessage(
                                          res.payload.data.message,
                                          res.payload.data.messageAr,
                                          loadReq
                                        );
                                      } else if (
                                        res.payload.data.statusCode === 401
                                      ) {
                                        dispatch(handleLogout());
                                        navigate("/cpc/customer/login");
                                        failedMessage(
                                          res.payload.data.error,
                                          res.payload.data.errorAr,
                                          loadReq
                                        );
                                      } else {
                                        setFieldValue("fuelValue", "");
                                        setFuelCardValue("00");
                                        failedMessage(
                                          res.payload.data.error,
                                          res.payload.data.errorAr,
                                          loadReq
                                        );
                                      }
                                    });
                                  }}
                                >
                                  {t("redeem")}
                                </Button>
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                className={`${styles.redeemBtnInput}`}
                                size="sm"
                                id="voucherCode"
                                name="voucherCode"
                                onChange={(e) => {
                                  setFieldValue(
                                    "voucherCode",
                                    e.currentTarget.value
                                  );
                                  if (e.target.value === "") {
                                    setRedeemDisable(true);
                                  } else {
                                    setRedeemDisable(false);
                                  }
                                }}
                                value={values.voucherCode}
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} className="mt-3">
                            <Form.Text>
                              <p className={`${styles.totalValuepara}`}>
                                {t("giftValue")}
                                <span
                                  className={`${styles.finalValueStyle} m-3 px-3 py-2`}
                                >
                                  {fuelCardValue}
                                </span>
                                {t("le")}
                              </p>
                            </Form.Text>
                          </Col>
                        </Fragment>
                      )}
                    </Form.Group>

                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                      <div className="d-grid">
                        <Button
                          type="submit"
                          variant="danger"
                          size="md"
                          className={`${styles.saveBtn} mb-3`}
                          disabled={sendDisabled === true ? true : isSubmitting}
                        >
                          {t("sendRequest")}
                        </Button>
                      </div>
                    </Col>
                  </Form>
                );
              }}
            </Formik>

            {accepted === true || pendTrans.status === true ? (
              <Formik
                initialValues={{
                  confirmCode: "",
                  transID: "",
                }}
                onSubmit={(values, actions) => {
                  const loadReq = toast.loading(t("loading"), {
                    transition: Zoom,
                    position:
                      i18n.language === "ar" ? "bottom-right" : "bottom-left",
                  });
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 3000);
                  if (fuelValue === 1) {
                    dispatch(
                      confirmRequestCode({
                        token,
                        confirmCode: values.confirmCode,
                        transID:
                          pendTrans.status === true ? pendTrans.id : transID,
                      })
                    ).then((res) => {
                      console.log(res);
                      if (res.payload.data.statusCode === 200) {
                        successMessage(
                          res.payload.data.message,
                          res.payload.data.messageAr,
                          loadReq
                        );
                        navigate("/customer/dashboard/home");
                      } else if (res.payload.data.statusCode === 401) {
                        dispatch(handleLogout());
                        navigate("/cpc/customer/login");
                        failedMessage(
                          res.payload.data.error,
                          res.payload.data.errorAr,
                          loadReq
                        );
                      } else {
                        failedMessage(
                          res.payload.data.error,
                          res.payload.data.errorAr,
                          loadReq
                        );
                      }
                    });
                  } else {
                    dispatch(
                      redeemFuelVoucher({
                        token,
                        voucherCode: fuelVoucherCode,
                        confirmCode: values.confirmCode,
                        transID:
                          pendTrans.status === true ? pendTrans.id : transID,
                      })
                    ).then((res) => {
                      if (res.payload.data.statusCode === 200) {
                        successMessage(
                          res.payload.data.message,
                          res.payload.data.messageAr,
                          loadReq
                        );
                        navigate("/customer/dashboard/home");
                      } else if (res.payload.data.statusCode === 401) {
                        dispatch(handleLogout());
                        navigate("/cpc/customer/login");
                        failedMessage(
                          res.payload.data.error,
                          res.payload.data.errorAr,
                          loadReq
                        );
                      } else {
                        failedMessage(
                          res.payload.data.error,
                          res.payload.data.errorAr,
                          loadReq
                        );
                      }
                    });
                  }
                }}
                validationSchema={confirmcodeValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => {
                  return (
                    <Form
                      onSubmit={handleSubmit}
                      onKeyDown={(e) => checkKeyDown(e)}
                    >
                      <Form.Group
                        as={Row}
                        className={`${styles.cashoutInputContent} mb-3 p-3`}
                      >
                        <Col xs={12} md={6} className="mb-2">
                          <Form.Label className="d-block">
                            ادخل رمز تاكيد الطلب
                          </Form.Label>
                          <Form.Text>
                            *اطلب من عامل المحطة رمز التاكيد المرسل فى رسالة
                            نصية الى هاتف المحطة
                          </Form.Text>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Control
                            type="text"
                            maxLength={4}
                            className={`${styles.cashoutInput} text-center`}
                            size="sm"
                            id="confirmCode"
                            name="confirmCode"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={handleChange}
                            value={values.confirmCode}
                          />
                          {errors.confirmCode && touched.confirmCode && (
                            <Form.Text className="text-error">
                              {errors.confirmCode}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>
                      <div className="d-grid">
                        <Button
                          type="submit"
                          variant="danger"
                          size="md"
                          className={`${styles.saveBtn}`}
                          disabled={isSubmitting}
                        >
                          {t("confirmPayment")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : null}
          </Col>
        </Row>
      </Container>
      <Qrscan
        show={showQrscan}
        onHide={() => {
          setShowQrscan(false);
        }}
        handlefinalresult={handleFinalResult}
      />
    </div>
  );
}
