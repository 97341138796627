import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, NavLink } from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "../../../../redux/features/userSlice";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import styles from "./GiftCards.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Message from "../../message/Message";
import i18next from "i18next";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { sendGift } from "../../../../redux/features/TraderSlice";
import Terms from "./terms/Terms";

export default function GiftCards(props) {
  const [count, setCount] = useState(100);
  const [step, setStep] = useState(1);
  const [vouhcers, setVouchers] = useState([]);
  const [cats, setCats] = useState([]);
  const [catType, setCatType] = useState("");

  const token = useSelector((state) => state.cpc.user.login.token);

  useEffect(() => {
    if (props.stepnow == 1) {
      setStep(1);
    }
  });

  useEffect(() => {
    axios
      .post(
        "https://evouchers.shareecoin.com/CPC/redeem/listVouchers",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          setCats(Object.keys(res.data.data));
          if (catType !== "") {
            setVouchers(res.data.data[catType]);
          } else {
            setCats(Object.keys(res.data.data));
            const allItems = [];
            Object.values(res.data.data).map((vouch) => {
              vouch.map((vo) => {
                allItems.push(vo);
              });
            });
            setVouchers(allItems);
          }
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/ownerTrader/login");
        }
      });
  }, [catType]);

  // const allItems = [];
  // Object.values(res.data.data.data).map((vouch) => {
  //   vouch.map((vo) => {
  //     allItems.push(vo);
  //   });
  // });
  // setVouchers(allItems);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [showMsg, setShowMsg] = useState(false);
  const [finalMessage, setFinalMessage] = useState("");

  const [termsMessage, setTermsMessage] = useState(false);

  useEffect(() => {
    if (step === 2) {
      setTermsMessage(true);
    }
  }, [step]);

  return (
    <div className={`${styles.giftCardContent}`}>
      <Container>
        <Row>
          <Col xs={12}>
            <h4 className={`${styles.redeemGiftTitle}`}>{t("egiftnote")}</h4>
          </Col>
          <Col xs={12} className="mt-4">
            <Formik
              initialValues={{
                catType: "",
                voucher: {
                  productID: "",
                  voucherName: "",
                  voucherDesc: "",
                  ImageUrl: "",
                  vouchValues: [],
                },
                ProductId: "",
                giftValue: "",
                enteredPassword: "",
              }}
              onSubmit={(values) => {
                dispatch(
                  sendGift({
                    ProductId: values.ProductId,
                    value: values.giftValue,
                    enteredPassword: values.enteredPassword,
                    token,
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    setShowMsg(true);
                    if (i18next.language == "ar") {
                      setFinalMessage(res.payload.data.messageAr);
                    } else {
                      setFinalMessage(res.payload.data.message);
                    }
                  } else if (res.payload.data.statusCode === 401) {
                    dispatch(handleLogout());
                    navigate("/cpc/ownerTrader/login");
                    const loadReq = toast.loading("Loading...", {
                      transition: Zoom,
                      position: "bottom-left",
                    });
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data.errorAr,
                      loadReq
                    );
                  } else {
                    const loadReq = toast.loading("Loading...", {
                      transition: Zoom,
                      position: "bottom-left",
                    });
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data.errorAr,
                      loadReq
                    );
                  }
                });
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    {step === 1 ? (
                      <Form.Group
                        as={Row}
                        className={`${styles.cashoutInputContent} mb-3 p-3`}
                      >
                        <Col xs={12}>
                          <div className={`${styles.catsContent} w-100 mb-3`}>
                            <div className="m-2">
                              <input
                                hidden
                                type="radio"
                                id="allcats"
                                name="catType"
                                className={`${styles.catCheck}`}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setCatType("");
                                    setFieldValue("catType", "");
                                  }
                                }}
                                defaultChecked={true}
                              />
                              <label
                                htmlFor={`allcats`}
                                className={`${styles.catTypeBtn}`}
                              >
                                All
                              </label>
                            </div>
                            {cats.map((cat, index) => {
                              return (
                                <div className="m-2" key={index}>
                                  <input
                                    hidden
                                    type="radio"
                                    id={`catType[${index + 1}]`}
                                    name={`catType`}
                                    className={`${styles.catCheck}`}
                                    onChange={(e) => {
                                      if (e.target.checked === true) {
                                        setCatType(cat);
                                        setFieldValue("catType", cat);
                                      }
                                    }}
                                  />
                                  <label
                                    htmlFor={`catType[${index + 1}]`}
                                    className={`${styles.catTypeBtn}`}
                                  >
                                    {cat}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                          <div
                            className={`${styles.vouchersContent} w-100 d-flex flex-column`}
                          >
                            <div className="d-flex flex-wrap justify-content-evenly align-items-start">
                              {vouhcers.length > 0 &&
                                vouhcers.map((vouch, index) => {
                                  return (
                                    <div className="m-1" key={index}>
                                      <input
                                        hidden
                                        type="radio"
                                        id={`vouch-[${vouch["ProductId"]}]`}
                                        name={`voucher-${catType}`}
                                        className={`${styles.rewardCheck} `}
                                        onChange={(e) => {
                                          setFieldValue("voucher", {
                                            productID: vouch["ProductId"],
                                            voucherName: vouch["VoucherName"],
                                            voucherDesc: vouch["Description"],
                                            ImageUrl: vouch["ImageUrl"],
                                            vouchValues: vouch["Values"],
                                          });
                                          setFieldValue(
                                            "ProductId",
                                            vouch["ProductId"]
                                          );
                                          props.stepback(2);
                                          setStep(2);
                                        }}
                                      />
                                      <label
                                        htmlFor={`vouch-[${vouch["ProductId"]}]`}
                                        className={`${styles.voucherBtn}`}
                                      >
                                        <img src={vouch.ImageUrl} />
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </Col>
                      </Form.Group>
                    ) : (
                      step === 2 && (
                        <div>
                          <Form.Group
                            as={Row}
                            className={`${styles.cashoutInputContent} mb-3 p-3`}
                          >
                            <div className="d-flex align-items-center mb-2">
                              <img
                                src={values.voucher.ImageUrl}
                                alt="cpc-merchant"
                                className={`${styles.giftCardImg} me-3`}
                              />
                              <div className={`${styles.giftCardInfo}`}>
                                <h5>{values.voucher.voucherName}</h5>
                                <span>{values.catType}</span>
                              </div>
                            </div>
                            <NavLink
                              onClick={() => {
                                setTermsMessage(true);
                              }}
                              className={`${styles.termsBtn}`}
                            >
                              <Form.Text className="d-block">
                                *{t("sendGiftNote")}
                              </Form.Text>
                            </NavLink>
                            <div className="d-flex flex-wrap justify-content-center">
                              {values.voucher.vouchValues.map((val, n) => {
                                return (
                                  <div className="m-2" key={n}>
                                    <input
                                      hidden
                                      type="radio"
                                      id={`vouchValue-${n}`}
                                      name="vouchValue"
                                      className={`${styles.vouchValuInput}`}
                                      onChange={(e) => {
                                        if (e.currentTarget.checked === true) {
                                          setFieldValue("giftValue", val);
                                        }
                                      }}
                                    />
                                    <label
                                      htmlFor={`vouchValue-${n}`}
                                      className={`${styles.voucherValueBtn}`}
                                    >
                                      {val}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className={`${styles.cashoutInputContent} mb-3 p-3`}
                          >
                            <Form.Label
                              column
                              sm={6}
                              className={`${styles.giftCardLabel}`}
                            >
                              {t("confirmPass")}
                            </Form.Label>
                            <Col sm={6}>
                              <Form.Control
                                type="password"
                                className={`${styles.giftCardInput}`}
                                id="confirmPassword"
                                name="confirmPassword"
                                onChange={(e) => {
                                  setFieldValue(
                                    "enteredPassword",
                                    e.currentTarget.value
                                  );
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <div className="d-flex">
                            <Button
                              type="submit"
                              variant="primary"
                              size="md"
                              className={`${styles.sendGiftBtn} me-3 w-100`}
                            >
                              {t("sendGift")}
                            </Button>
                            {/* <Button
                              variant="light"
                              size="md"
                              className={`${styles.closeBtn} w-25`}
                              onClick={() => {
                                setCatType("");
                                setStep(1);
                              }}
                            >
                              Back
                            </Button> */}
                          </div>
                        </div>
                      )
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
      <Message
        show={showMsg}
        onHide={() => {
          setShowMsg(false);
        }}
        message={finalMessage}
      />
      <Terms
        show={termsMessage}
        onHide={() => {
          setTermsMessage(false);
        }}
      />
    </div>
  );
}
