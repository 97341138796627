import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Formik } from "formik";
import { assignActivator } from "../../../../redux/features/adminSlice";
import { useNavigate } from "react-router-dom";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { handleLogout } from "../../../../redux/features/userSlice";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import styles from "./ShopAssigned.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ShopDeactivate from "../deactivateModal/ShopDeactivate";

export default function ShopAssigned({ owners }) {
  const [shopAssignedForm, setShopAssignedForm] = useState(false);
  const [shopID, setShopID] = useState("");
  const [activators, setActivators] = useState([]);
  const [activator, setActivator] = useState("");
  const [ownerID, setOwnerID] = useState("");

  const [showActs, setShowActs] = useState("");
  const [saveAssign, setSaveAssign] = useState(true);
  const [showAssign, setShowAssign] = useState(true);
  const [assignedTo, setAssignedTo] = useState("");

  const [showDeactivateShop, setShowDeactivatShop] = useState(false);
  const [deactivatedMerchantPhone, setDeactivatedMerchantPhone] = useState("");

  const token = useSelector((state) => state.cpc.user.login.token);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/reports/listUsers-modified", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const activatorss = res.data.data.filter((act) => {
            return act.CPCRole.roleName === "Activator";
          });
          const arrangeActivators = activatorss.sort(function (a, b) {
            var textA = a.firstName.toUpperCase();
            var textB = b.firstName.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          setActivators(arrangeActivators);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  console.log(activators);

  const handleSaveBtn = (ownerID) => {
    const saveButton = document.getElementById(`saveBtn-${ownerID}`);
    saveButton.disabled = false;
  };

  const handleShowAssign = (ownerID) => {
    const showcontent = document.getElementById(`assignContent-${ownerID}`);
    showcontent.classList.toggle("d-none");
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  console.log(owners);

  return (
    <div className={`${styles.assignedContent} p-2`}>
      {owners.map((owner) => {
        return (
          <div
            className={`${styles.bgGrey} ${
              owner.status === 1 && styles.bgActivate
            }
            ${owner.status === 2 && styles.bgNottActivee}
            px-4 py-3 mb-3`}
            key={Math.random() * 1000}
          >
            <div
              className={`${styles.activatorInfoContent} d-flex align-items-center`}
            >
              <img
                src={require("../../../../imgs/shop2.jpg")}
                className="me-2"
              />
              <div className={`${styles.activatorInfo} mt-2`}>
                <h5>{owner.businessName}</h5>
                <p>{`${t("ownerName")}: ${owner.firstName} ${
                  owner.lastName
                }`}</p>
                {owner.status === 1 && (
                  <h6 className={`${styles.activatedStatus} mt-3`}>
                    <Badge bg="success">{t("activated")}</Badge>
                  </h6>
                )}
                {owner.status === 2 && (
                  <h6 className={`${styles.activatedStatus} mt-3`}>
                    <Badge bg="danger">{t("deactived")}</Badge>
                  </h6>
                )}
              </div>
            </div>
            <div
              className={`${styles.activatorBtnContent} h-100 d-flex justify-content-left justify-content-md-center align-items-center flex-wrap mt-2`}
            >
              {owner["assigned_id"] === 1 && owner.status === 0 && (
                <Button
                  variant="primary"
                  className={`${styles.editBtn} me-2`}
                  onClick={() => {
                    handleShowAssign(owner.userID);
                  }}
                >
                  {t("assignToActivator")}
                </Button>
              )}
              {owner.status === 1 && (
                <Button
                  variant="warning"
                  className={`${styles.deactivateBtn} me-2`}
                  onClick={() => {
                    setShowDeactivatShop(true);
                    setDeactivatedMerchantPhone(owner.phone);
                  }}
                >
                  {t("deactivate")}
                </Button>
              )}
              <Button
                variant="primary"
                className={`${styles.detailsBtn}`}
                onClick={() => {
                  navigate(
                    `/admin/dashboard/merchants/all/details/${owner.userID}`
                  );
                }}
              >
                {t("details")}
              </Button>
            </div>
            {owner["assigned_id"] !== 1 &&
            owner.status === 0 &&
            showAssign == true ? (
              <Container>
                <Row>
                  <Col xs={12} className="mt-3">
                    <div>
                      <Formik
                        initialValues={{
                          activatorName: "",
                          governFilter: true,
                        }}
                        onSubmit={(values) => {
                          const loadReq = toast.loading("Loading...", {
                            transition: Zoom,
                            position: "bottom-left",
                          });
                          dispatch(
                            assignActivator({
                              shopID: owner.userID,
                              activatorID: values.activatorName,
                              token,
                            })
                          ).then((res) => {
                            if (res.payload.data.statusCode === 200) {
                              successMessage(
                                res.payload.data.message,
                                res.payload.data.messageAr,
                                loadReq
                              );
                              setTimeout(() => {
                                window.location.reload();
                              }, 2000);
                            } else if (res.payload.data.statusCode === 401) {
                              dispatch(handleLogout());
                              navigate("/cpc/login");
                              failedMessage(
                                res.payload.data.error,
                                res.payload.data.errorAr,
                                loadReq
                              );
                            } else {
                              failedMessage(
                                res.payload.data.error,
                                res.payload.data.errorAr,
                                loadReq
                              );
                            }
                          });
                        }}
                      >
                        {({
                          values,
                          errors,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                        }) => {
                          return (
                            <Form
                              id={`assignedForm-${owner.userID}`}
                              onSubmit={handleSubmit}
                            >
                              <Form.Group as={Row}>
                                <Form.Label
                                  column
                                  sm={12}
                                  className={`${styles.activatorLabel}`}
                                >
                                  {t("assignTo")}:
                                  <span className="mx-1">
                                    {activators
                                      .filter((activ) => {
                                        return (
                                          activ.userID === owner["assigned_id"]
                                        );
                                      })
                                      .map(
                                        (ac) => ac.firstName + " " + ac.lastName
                                      )}
                                  </span>
                                </Form.Label>
                                <Col xs={12} className="my-3">
                                  <div className="d-flex justify-content-start align-items-center">
                                    <input
                                      name="govern"
                                      id={`govern-all-${owner["districts.governorate.id"]}`}
                                      type="radio"
                                      onChange={(e) => {
                                        setFieldValue("governFilter", false);
                                      }}
                                      className={`${styles.governInput} d-none`}
                                    />
                                    <label
                                      htmlFor={`govern-all-${owner["districts.governorate.id"]}`}
                                      className={`${styles.governFilterInput} me-1`}
                                    >
                                      {t("showAll")}
                                    </label>
                                    <input
                                      name="govern"
                                      id={`govern-${owner["districts.governorate.id"]}`}
                                      type="radio"
                                      className={`${styles.governInput} d-none`}
                                      onChange={(e) => {
                                        setFieldValue("governFilter", true);
                                      }}
                                      defaultChecked={true}
                                    />
                                    <label
                                      htmlFor={`govern-${owner["districts.governorate.id"]}`}
                                      className={`${styles.governFilterInput}`}
                                    >
                                      {i18next.language === "en"
                                        ? owner[
                                            "districts.governorate.governorate_name_en"
                                          ]
                                        : owner[
                                            "districts.governorate.governorate_name_ar"
                                          ]}
                                    </label>
                                  </div>
                                </Col>
                                <Col sm={12} md={6}>
                                  <Form.Select
                                    className={`${styles.activatorSelect}`}
                                    name="activatorName"
                                    id="activatorName"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "activatorName",
                                        e.currentTarget.value
                                      );
                                      handleSaveBtn(owner.userID);
                                    }}
                                    value={values.activatorName}
                                  >
                                    <option value="">
                                      {t("activatorName")}
                                    </option>
                                    {values.governFilter == false
                                      ? activators.map((act, index) => {
                                          return (
                                            <option
                                              value={act.userID}
                                              key={index}
                                            >
                                              {`${act.firstName} ${
                                                act.lastName
                                              } - ${act.districts.map(
                                                (dis) =>
                                                  `${dis.city["city_name_en"]},${dis.governorate["governorate_name_en"]}`
                                              )}
                                              `}
                                            </option>
                                          );
                                        })
                                      : activators
                                          .filter((actt) => {
                                            return (
                                              actt.districts[0][
                                                "governorate_id"
                                              ] ==
                                              owner["districts.governorate.id"]
                                            );
                                          })
                                          .map((act, index) => {
                                            return (
                                              <option
                                                value={act.userID}
                                                key={index}
                                              >
                                                {`${act.firstName} ${
                                                  act.lastName
                                                } - ${act.districts.map(
                                                  (dis) =>
                                                    `${dis.city["city_name_en"]},${dis.governorate["governorate_name_en"]}`
                                                )}`}
                                              </option>
                                            );
                                          })}
                                  </Form.Select>
                                </Col>
                              </Form.Group>
                              <Button
                                type="submit"
                                variant="primary"
                                size="sm"
                                className={`${styles.saveBtn} mt-2`}
                                form={`assignedForm-${owner.userID}`}
                                id={`saveBtn-${owner.userID}`}
                                disabled={true}
                              >
                                {t("save")}
                              </Button>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </Col>
                </Row>
              </Container>
            ) : (
              showAssign === true && (
                <Container
                  id={`assignContent-${owner.userID}`}
                  className="d-none"
                >
                  <Row>
                    <Col xs={12} className="mt-3">
                      <div className="w-50">
                        <Formik
                          initialValues={{
                            activatorName: "",
                            governFilter: true,
                          }}
                          onSubmit={(values) => {
                            const loadReq = toast.loading("Loading...", {
                              transition: Zoom,
                              position: "bottom-left",
                            });
                            dispatch(
                              assignActivator({
                                shopID: owner.userID,
                                activatorID: values.activatorName,
                                token,
                              })
                            ).then((res) => {
                              if (res.payload.data.statusCode === 200) {
                                successMessage(
                                  res.payload.data.message,
                                  res.payload.data.messageAr,
                                  loadReq
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 2000);
                              } else if (res.payload.data.statusCode === 401) {
                                dispatch(handleLogout());
                                navigate("/cpc/login");
                                failedMessage(
                                  res.payload.data.error,
                                  res.payload.data.errorAr,
                                  loadReq
                                );
                              } else {
                                failedMessage(
                                  res.payload.data.error,
                                  res.payload.data.errorAr,
                                  loadReq
                                );
                              }
                            });
                          }}
                        >
                          {({
                            values,
                            errors,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                          }) => {
                            return (
                              <Form
                                id={`assignedForm-${owner.userID}`}
                                onSubmit={handleSubmit}
                              >
                                <Form.Group>
                                  <Form.Label
                                    className={`${styles.activatorLabel}`}
                                  >
                                    {t("assignTo")}:
                                    <span className="mx-1">
                                      {activators
                                        .filter((activ) => {
                                          return (
                                            activ.userID ===
                                            owner["assigned_id"]
                                          );
                                        })
                                        .map(
                                          (ac) =>
                                            ac.firstName + " " + ac.lastName
                                        )}
                                    </span>
                                  </Form.Label>
                                  <Col xs={12} className="my-3">
                                    <div className="d-flex justify-content-start align-items-center">
                                      <input
                                        name="govern"
                                        id={`govern-all-${owner["districts.governorate.id"]}`}
                                        type="radio"
                                        onChange={(e) => {
                                          setFieldValue("governFilter", false);
                                        }}
                                        className={`${styles.governInput} d-none`}
                                      />
                                      <label
                                        htmlFor={`govern-all-${owner["districts.governorate.id"]}`}
                                        className={`${styles.governFilterInput} me-1`}
                                      >
                                        {t("showAll")}
                                      </label>
                                      <input
                                        name="govern"
                                        id={`govern-${owner["districts.governorate.id"]}`}
                                        type="radio"
                                        className={`${styles.governInput} d-none`}
                                        onChange={(e) => {
                                          setFieldValue("governFilter", true);
                                        }}
                                        defaultChecked={true}
                                      />
                                      <label
                                        htmlFor={`govern-${owner["districts.governorate.id"]}`}
                                        className={`${styles.governFilterInput}`}
                                      >
                                        {i18next.language === "en"
                                          ? owner[
                                              "districts.governorate.governorate_name_en"
                                            ]
                                          : owner[
                                              "districts.governorate.governorate_name_ar"
                                            ]}
                                      </label>
                                    </div>
                                  </Col>
                                  <Form.Select
                                    className={`${styles.activatorSelect}`}
                                    name="activatorName"
                                    id="activatorName"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "activatorName",
                                        e.currentTarget.value
                                      );
                                      handleSaveBtn(owner.userID);
                                    }}
                                    value={values.activatorName}
                                  >
                                    <option value="">
                                      {t("activatorName")}
                                    </option>
                                    {values.governFilter == false
                                      ? activators.map((act, index) => {
                                          return (
                                            <option
                                              value={act.userID}
                                              key={index}
                                            >
                                              {`${act.firstName} ${
                                                act.lastName
                                              } - ${act.districts.map(
                                                (dis) =>
                                                  `${dis.city["city_name_en"]},${dis.governorate["governorate_name_en"]}`
                                              )}`}
                                            </option>
                                          );
                                        })
                                      : activators
                                          .filter((actt) => {
                                            return (
                                              actt.districts[0][
                                                "governorate_id"
                                              ] ==
                                              owner["districts.governorate.id"]
                                            );
                                          })
                                          .map((act, index) => {
                                            return (
                                              <option
                                                value={act.userID}
                                                key={index}
                                              >
                                                {`${act.firstName} ${
                                                  act.lastName
                                                } - ${act.districts.map(
                                                  (dis) =>
                                                    `${dis.city["city_name_en"]},${dis.governorate["governorate_name_en"]}`
                                                )}`}
                                              </option>
                                            );
                                          })}
                                  </Form.Select>
                                </Form.Group>
                                <Button
                                  type="submit"
                                  variant="primary"
                                  size="sm"
                                  className={`${styles.saveBtn} mt-2`}
                                  form={`assignedForm-${owner.userID}`}
                                  id={`saveBtn-${owner.userID}`}
                                  disabled={true}
                                >
                                  {t("save")}
                                </Button>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )
            )}
          </div>
        );
      })}
      <ShopDeactivate
        show={showDeactivateShop}
        onHide={() => {
          setShowDeactivatShop(false);
        }}
        handlemerchantphone={deactivatedMerchantPhone}
      />
    </div>
  );
}
