import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { handleLogout } from "../../../../redux/features/userSlice";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./EditShopOfOwner.module.scss";
import { editShop } from "../../../../redux/features/activatorSlice";

export default function EditShopOfOwner() {
  const [cities, setCities] = useState([]);
  const [governs, setGoverns] = useState([]);
  const [governID, setGovernID] = useState(null);
  const [activeCity, setAcitveCity] = useState(false);

  const [shop, setShop] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [nationalID, setNationalID] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [addressAr, setAddressAr] = useState("");
  const [branchName, setBranchName] = useState("");
  const [traderGovID, setTraderGovID] = useState("");
  const [traderCityID, setTraderCityID] = useState("");

  const [details, setDetails] = useState([]);

  const { shopID } = useParams();
  const { ownerID } = useParams();

  console.log(shopID, ownerID);

  const token = useSelector((state) => state.cpc.user.login.token);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setGoverns(res.data["Governates List"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: governID,
        Function: "ListCites",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCities(res.data["List of Cities"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [governID]);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/traderList", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const filterTrader = res.data.traders.filter((value, trade, key) => {
            return value["shop_details"][0]["parent_merchant_id"] == ownerID;
          });
          const editTradeder = filterTrader.filter((editt) => {
            return editt.id == shopID;
          });
          setShop(editTradeder);
          editTradeder.map((ed) => {
            setFirstName(ed.firstName);
            setLastName(ed.lastName);
            setEmail(ed.email);
            setNationalID(ed.NID);
            setBranchName(ed["shop_details"][0]["branchName"]);
            setAddress(ed["shop_details"][0]["address"]);
            setAddressAr(ed["shop_details"][0]["addressAR"]);
            setPhone(ed.phone);
            setTraderGovID(ed["districts"][0]["governorate_id"]);
            setGovernID(ed["districts"][0]["governorate_id"]);
            setTraderCityID(ed["districts"][0]["city_id"]);
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className={`${styles.editShopContent}`}>
      <Container>
        <Row className="mb-2">
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>Edit Shop</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                nationalID: "",
                branchName: "",
                phone: "",
                traderGovID: "",
                traderCityID: "",
                address: "",
                addressAr: "",
              }}
              onSubmit={(values) => {
                console.log(values);
                dispatch(
                  editShop({
                    token,
                    shopID,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone,
                    nationalID: nationalID,
                    govID: traderGovID,
                    cityID: traderCityID,
                    branchName: branchName,
                    address: address,
                    addressAr: addressAr,
                  })
                ).then((res) => {
                  console.log(res);
                  if (res.payload.data.statusCode === 200) {
                    navigate(`/activator/dashboard/${ownerID}/viewshops`);
                  }
                  if (res.payload.data.statusCode === 401) {
                    dispatch(handleLogout());
                    navigate("/cpc/login");
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form
                    className={`${styles.editBranchForm}`}
                    onSubmit={handleSubmit}
                  >
                    <Row className="mb-3">
                      <Form.Group as={Col} xs={12} sm={6} className="mb-3">
                        <Form.Label className={`${styles.editShopLabel}`}>
                          First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          className={`${styles.editShopInput}`}
                          name="firstName"
                          id="firstName"
                          onChange={(e) => {
                            setFirstName(e.currentTarget.value);
                            setFieldValue("firstName", e.currentTarget.value);
                          }}
                          value={firstName}
                        />
                      </Form.Group>
                      <Form.Group as={Col} xs={12} sm={6}>
                        <Form.Label className={`${styles.editShopLabel}`}>
                          Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          className={`${styles.editShopInput}`}
                          name="lastName"
                          id="lastName"
                          onChange={(e) => {
                            setLastName(e.currentTarget.value);
                            setFieldValue("lastName", e.currentTarget.value);
                          }}
                          value={lastName}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} xs={12} sm={6} className="mb-3">
                        <Form.Label className={`${styles.editShopLabel}`}>
                          Email
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Email"
                          className={`${styles.editShopInput}`}
                          name="email"
                          id="email"
                          onChange={(e) => {
                            setEmail(e.currentTarget.value);
                            setFieldValue("email", e.currentTarget.value);
                          }}
                          value={email}
                        />
                      </Form.Group>
                      <Form.Group as={Col} xs={12} sm={6}>
                        <Form.Label className={`${styles.editShopLabel}`}>
                          National ID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="National ID"
                          className={`${styles.editShopInput}`}
                          name="nationalID"
                          id="nationalID"
                          onChange={(e) => {
                            setNationalID(e.currentTarget.value);
                            setFieldValue("nationalID", e.currentTarget.value);
                          }}
                          value={nationalID}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} xs={12} sm={6}>
                        <Form.Label className={`${styles.editShopLabel}`}>
                          Shop Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Shop Name"
                          className={`${styles.editShopInput}`}
                          name="branchName"
                          id="branchName"
                          onChange={(e) => {
                            setBranchName(e.currentTarget.value);
                            setFieldValue("branchName", e.currentTarget.value);
                          }}
                          value={branchName}
                        />
                      </Form.Group>
                      <Col xs={12}>
                        <h5 className={`${styles.shopInfoTitle} mt-3`}>
                          Shop Information:
                        </h5>
                      </Col>
                    </Row>
                    <div className={`${styles.bgBlue} p-3`}>
                      <Container>
                        <Row>
                          <Col className="mt-4">
                            <Row className="mb-3">
                              <Form.Group as={Col} xs={12} sm={6}>
                                <Form.Label
                                  className={`${styles.editBranchLabel}`}
                                >
                                  Shop Phone Number
                                </Form.Label>
                                <Form.Control
                                  type="tel"
                                  placeholder="Shop Phone Number"
                                  className={`${styles.editBranchInput}`}
                                  name="phone"
                                  id="phone"
                                  onChange={(e) => {
                                    setPhone(e.currentTarget.value);
                                    setFieldValue(
                                      "phone",
                                      e.currentTarget.value
                                    );
                                  }}
                                  value={phone}
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                xs={12}
                                sm={6}
                                className="mb-3"
                              >
                                <Form.Label
                                  className={`${styles.editBranchLabel}`}
                                >
                                  Governorate
                                </Form.Label>
                                <Form.Select
                                  className={`${styles.addSelect}`}
                                  name="traderGovID"
                                  id="traderGovID"
                                  onChange={(e) => {
                                    setGovernID(e.currentTarget.value);
                                    setTraderGovID(e.currentTarget.value);
                                    setFieldValue(
                                      "traderGovID",
                                      e.currentTarget.value
                                    );
                                    setAcitveCity(true);
                                  }}
                                  value={traderGovID}
                                >
                                  <option value="">Select Governorate</option>
                                  {governs.map((gov) => {
                                    return (
                                      <option
                                        key={gov.GovernateID}
                                        value={gov.GovernateID}
                                      >
                                        {gov["governorate_name_en"]}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                              <Form.Group as={Col} xs={12} sm={6}>
                                <Form.Label
                                  className={`${styles.editBranchLabel}`}
                                >
                                  Districts
                                </Form.Label>
                                <Form.Select
                                  className={`${styles.addSelect}`}
                                  name="traderCityID"
                                  id="traderCityID"
                                  onChange={(e) => {
                                    setTraderCityID(e.currentTarget.value);
                                    setFieldValue(
                                      "traderCityID",
                                      e.currentTarget.value
                                    );
                                  }}
                                  value={traderCityID}
                                  disabled={!activeCity}
                                >
                                  <option value="">Select District</option>
                                  {cities.map((city) => {
                                    return (
                                      <option
                                        key={city.cityID}
                                        value={city.cityID}
                                      >
                                        {city.city_name_en}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                xs={12}
                                sm={6}
                                className="mb-3"
                              >
                                <Form.Label
                                  className={`${styles.editBranchLabel}`}
                                >
                                  Branch Address
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Branch Address"
                                  className={`${styles.editBranchInput}`}
                                  name="address"
                                  id="address"
                                  onChange={(e) => {
                                    setAddress(e.currentTarget.value);
                                    setFieldValue(
                                      "address",
                                      e.currentTarget.value
                                    );
                                  }}
                                  value={address}
                                />
                              </Form.Group>
                              <Form.Group as={Col} xs={12} sm={6}>
                                <Form.Label
                                  className={`${styles.editBranchLabel}`}
                                >
                                  Branch Address In Arabic
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Branch Address In Arabic"
                                  className={`${styles.editBranchInput}`}
                                  name="addressAr"
                                  id="addressAr"
                                  onChange={(e) => {
                                    setAddressAr(e.currentTarget.value);
                                    setFieldValue(
                                      "addressAr",
                                      e.currentTarget.value
                                    );
                                  }}
                                  value={addressAr}
                                />
                              </Form.Group>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    <Row>
                      <Col xs={12} md={{ span: 4, offset: 4 }} className="mt-3">
                        <div className="d-grid">
                          <Button
                            type="submit"
                            variant="primary"
                            className={`${styles.saveBtn}`}
                          >
                            Save
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
