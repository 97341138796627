import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./StationHome.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import i18next from "i18next";
import axios from "axios";
import { handleLogout } from "../../../redux/features/userSlice";
import Pagination from "../../pagination/Pagination";
import {
  activateOperator,
  createPDFQrCode,
  deactivateOperator,
} from "../../../redux/features/stationSlice";
import fileDownload from "js-file-download";

export default function StationHome() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [operators, setOperators] = useState([]);
  const [activeOpe, setActiveOpe] = useState([]);
  const [notactiveOpe, setNotactiveOpe] = useState([]);
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [total, setTotal] = useState("");

  const token = useSelector((state) => state.cpc.user.login.token);
  const userID = useSelector((state) => state.cpc.user.login.userID);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/operators", {
        params: {
          sort: "desc",
          sortBy: "date",
          status: "",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          let stations = res.data.data;
          const filterStation = stations.filter(
            (station) => station["user_id"] == userID
          );
          filterStation.map((sat) => {
            setOperators(sat["stationOperators"]);
          });
          setTotal(operators.length);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/stationOwner/login");
        }
      });
  }, []);

  console.log(operators);

  const postLastIndex = page * postsPerPage;
  const postFirstIndex = postLastIndex - postsPerPage;
  const currentPosts = operators.slice(postFirstIndex, postLastIndex);

  let generateFile = (content, fileName) => {};

  return (
    <div className={`${styles.stationHomeContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              {t("stations")}
            </h3>
          </Col>
          <Col xs={12} md={6}>
            <div className={`${styles.btnRight}`}>
              <Button
                size="md"
                variant="secondary"
                className={`${styles.reportBtn} me-2 mb-2`}
                onClick={() => {
                  navigate("/stationOwner/dashboard/home/report");
                }}
              >
                {t("stationReportt")}
              </Button>
              <Button
                size="md"
                variant="primary"
                className="mb-2"
                onClick={() => {
                  navigate("/stationOwner/dashboard/home/add/operator");
                }}
              >
                {t("addOperator")}
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
              <div className={`${styles.filterForm} mb-3 mb-lg-0`}>
                {/* <Formik
                  initialValues={{ governID: "" }}
                  onSubmit={(values) => {
                    setGovernId(values.governID);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputGroup className={`${styles.filterInput}`}>
                          <InputGroup.Text className={`${styles.filterLabel}`}>
                            <HiFilter />
                          </InputGroup.Text>
                          <Form.Select
                            size="sm"
                            name="governID"
                            id="governID"
                            onChange={(e) => {
                              setFieldValue("governID", e.currentTarget.value);
                              handleSubmit();
                            }}
                            className={`${styles.filterSelect}`}
                            value={values.governID}
                          >
                            <option value="">{t("gover")}</option>
                            {governorates.map((govern, index) => {
                              return (
                                <option key={index} value={govern.GovernateID}>
                                  {i18next.language == "ar"
                                    ? govern["governorate_name_ar"]
                                    : govern["governorate_name_en"]}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </InputGroup>
                      </Form>
                    );
                  }}
                </Formik> */}
              </div>
              <div className={`${styles.searchForm}`}>
                {/* <Formik
                  initialValues={{ searchValue: "" }}
                  onSubmit={(values) => {}}
                >
                  {({ values, touched, handleChange, handleSubmit }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputGroup className={`${styles.searchInput}`}>
                          <InputGroup.Text className={`${styles.searchLabel}`}>
                            <BiSearch />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder={t("search")}
                            className={`${styles.searchField}`}
                            id="searchValue"
                            name="searchValue"
                            onChange={(e) => {
                              if (page !== 1) {
                                setPage(1);
                              }
                              setSearchValue(e.currentTarget.value);
                            }}
                          />
                        </InputGroup>
                      </Form>
                    );
                  }}
                </Formik> */}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {currentPosts.map((operator, index) => {
            if (operator.status == 1) {
              return (
                <Col xs={12} key={index}>
                  <div className={`${styles.bgGrey} py-3 px-4 mb-3`}>
                    <div
                      className={`${styles.activatorInfoContent} d-flex align-items-center`}
                    >
                      <div className={`${styles.activatorInfo}`}>
                        <h5>{operator["phone_number"]}</h5>
                        <p>{operator["name"]}</p>
                        {/* <span className="mt-3">01121830506</span> */}
                      </div>
                    </div>
                    <div
                      className={`${styles.activatorBtnContent}  h-100 d-flex justify-content-left justify-content-md-center align-items-center flex-wrap mt-2`}
                    >
                      <Button
                        variant="danger"
                        // className={`${styles.reportBtn} me-2`}
                        className={`${styles.deleteBtn} me-2`}
                        onClick={() => {
                          dispatch(
                            deactivateOperator({
                              token,
                              operatorID: operator["operator_id"],
                            })
                          ).then((res) => {
                            const loadReq = toast.loading(t("loading"), {
                              transition: Zoom,
                              position:
                                i18next.language == "ar"
                                  ? "bottom-right"
                                  : "bottom-left",
                            });
                            setTimeout(() => {
                              window.location.reload();
                            }, 3000);
                            if (res.payload.data.statusCode === 200) {
                              successMessage(
                                res.payload.data.message,
                                res.payload.data.messageAr,
                                loadReq
                              );
                            } else if (res.payload.data.statusCode === 401) {
                              dispatch(handleLogout());
                              navigate("/cpc/stationOwner/login");
                              failedMessage(
                                res.payload.data.error,
                                res.payload.data.errorAr,
                                loadReq
                              );
                            } else {
                              failedMessage(
                                res.payload.data.error,
                                res.payload.data.errorAr,
                                loadReq
                              );
                            }
                          });
                        }}
                      >
                        {t("deactivate")}
                      </Button>
                      <Button
                        variant="primary"
                        className={`${styles.detailsBtn} me-2`}
                        onClick={() => {
                          dispatch(
                            createPDFQrCode({
                              operatorID: operator["operator_id"],
                              token,
                            })
                          ).then((res) => {
                            console.log(res);
                            fileDownload(
                              res.payload.data,
                              `operator-${operator["name"]}-qrcode.pdf`
                            );
                          });
                        }}
                      >
                        {t("downloadQr")}
                      </Button>
                    </div>
                  </div>
                </Col>
              );
            } else {
              return (
                <Col xs={12} key={index}>
                  <div className={`${styles.bgGreyNotActive} px-4 mb-3`}>
                    <div
                      className={`${styles.activatorInfoContent} d-flex align-items-center`}
                    >
                      <div className={`${styles.activatorInfo}`}>
                        <h5>{operator["phone_number"]}</h5>
                        <p>{operator["name"]}</p>
                        {/* <span className="mt-3">01121830506</span> */}
                      </div>
                    </div>
                    <div
                      className={`${styles.activatorBtnContent}  h-100 d-flex justify-content-left justify-content-md-center align-items-center flex-wrap mt-2`}
                    >
                      <Button
                        variant="primary"
                        className={`${styles.detailsBtn} me-2`}
                        onClick={() => {
                          dispatch(
                            activateOperator({
                              token,
                              operatorID: operator["operator_id"],
                            })
                          ).then((res) => {
                            const loadReq = toast.loading(t("loading"), {
                              transition: Zoom,
                              position:
                                i18next.language == "ar"
                                  ? "bottom-right"
                                  : "bottom-left",
                            });
                            setTimeout(() => {
                              window.location.reload();
                            }, 3000);
                            if (res.payload.data.statusCode === 200) {
                              successMessage(
                                res.payload.data.message,
                                res.payload.data.messageAr,
                                loadReq
                              );
                            } else if (res.payload.data.statusCode === 401) {
                              dispatch(handleLogout());
                              navigate("/cpc/stationOwner/login");
                              failedMessage(
                                res.payload.data.error,
                                res.payload.data.errorAr,
                                loadReq
                              );
                            } else {
                              failedMessage(
                                res.payload.data.error,
                                res.payload.data.errorAr,
                                loadReq
                              );
                            }
                          });
                        }}
                      >
                        {t("activate")}
                      </Button>
                    </div>
                  </div>
                </Col>
              );
            }
          })}
          <Col xs={12}>
            <Pagination
              totalPosts={total}
              postsPerPage={postsPerPage}
              onChangePage={(value) => {
                setPage(value);
              }}
              currentPage={page}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
